import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { ProgramType } from "@/types";
import { lazy, Suspense } from "react";
import { useParams } from "react-router-dom";

const DescriptionDetail = lazy(
  () => import("@/dashboard/components/program/DescriptionDetail")
);
const PerformanceDetail = lazy(
  () => import("@/dashboard/components/program/PerformanceDetail")
);
const TerrainDetail = lazy(
  () => import("@/dashboard/components/program/TerrainDetail")
);
const DashboardProject = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useFetchDataV2<ProgramType>(
    `${import.meta.env.VITE_API_ENDPOINT}/project/${id}`
  );

  if (loading) return <LoadingSpinner />;

  error && new Error("Une erreur est survenue: " + error);

  return (
    <div>
      <h1 className="mb-4 text-2xl font-medium">Projet : {data?.name}</h1>
      <Tabs defaultValue="descriptif" className="w-full">
        <TabsList className="mb-2 grid w-full grid-cols-4 bg-background">
          <TabsTrigger
            className="rounded-none border-b-2 text-[#626262] data-[state=active]:border-foreground data-[state=active]:shadow-none"
            value="descriptif"
          >
            Descriptif du projet
          </TabsTrigger>
          <TabsTrigger
            value="terrain"
            className="rounded-none border-b-2 text-[#626262] data-[state=active]:border-foreground data-[state=active]:shadow-none"
          >
            Terrain rattaché
          </TabsTrigger>
          <TabsTrigger
            value="perf"
            className="rounded-none border-b-2 text-[#626262] data-[state=active]:border-foreground data-[state=active]:shadow-none"
          >
            Suivi de performance ESG
          </TabsTrigger>
          <TabsTrigger
            value="reporting"
            className="rounded-none border-b-2 text-[#626262] data-[state=active]:border-foreground data-[state=active]:shadow-none"
          >
            Reporting
          </TabsTrigger>
        </TabsList>
        <TabsContent value="descriptif" className="mb-4">
          <Suspense fallback={<LoadingSpinner />}>
            <DescriptionDetail project={data} />
          </Suspense>
        </TabsContent>
        <TabsContent value="terrain" className="mb-4">
          <Suspense fallback={<LoadingSpinner />}>
            <TerrainDetail project={data} />
          </Suspense>
        </TabsContent>
        <TabsContent value="perf" className="mb-4">
          <Suspense fallback={<LoadingSpinner />}>
            <PerformanceDetail data={data} />
          </Suspense>
        </TabsContent>
        <TabsContent value="reporting" className="mb-4"></TabsContent>
      </Tabs>
    </div>
  );
};

export default DashboardProject;
