import { User } from "@/types";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  checkAuth,
  checkMailAndSendCode,
  register,
  signIn,
} from "../services/authService";

interface AuthState {
  user: User | null;
  loading: boolean;
  error: string | null;
}

export const useAuth = () => {
  const [authState, setAuthState] = useState<AuthState>({
    user: null,
    loading: true,
    error: null,
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const loadUser = useCallback(async () => {
    setAuthState((prevState) => ({ ...prevState, loading: true }));
    try {
      const user = await checkAuth();
      setAuthState({ user, loading: false, error: null });
      if (user) setIsAuthenticated(true);
    } catch (error) {
      setAuthState({
        user: null,
        loading: false,
        error: "Failed to load user",
      });
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const signInUser = async (email: string, password: string) => {
    setAuthState((prevState) => ({ ...prevState, loading: true }));
    try {
      const user = await signIn({ email, password });
      setAuthState({ user, loading: false, error: null });
      if (user) setIsAuthenticated(true);
      return { success: true };
    } catch (error) {
      setAuthState({ user: null, loading: false, error: "Failed to sign in" });
      setIsAuthenticated(false);
      return { success: false };
    }
  };

  const registerUser = async (
    firstname: string,
    lastname: string,
    structureName: string,
    email: string,
    password: string,
    confirmPassword: string
  ) => {
    setAuthState((prevState) => ({ ...prevState, loading: true }));
    try {
      const { user, errors } = await register({
        firstname,
        lastname,
        structureName,
        email,
        password,
        confirmPassword,
      });
      if (errors) {
        setAuthState({
          user: null,
          loading: false,
          error: "Failed to register",
        });
        setIsAuthenticated(false);
        return { success: false, errors: errors };
      }
      setAuthState({ user, loading: false, error: null });
      if (user) setIsAuthenticated(true);
      return { success: true, errors: null };
    } catch (error) {
      setAuthState({ user: null, loading: false, error: "Failed to register" });
      setIsAuthenticated(false);
      return { success: false, errors: null };
    }
  };

  const logoutUser = useCallback(
    (urlRedirect?: string) => {
      // Supprimer le token JWT du localStorage
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("refreshToken");

      // Mettre à jour l'état pour refléter que l'utilisateur est déconnecté
      setAuthState({ user: null, loading: false, error: null });

      setIsAuthenticated(false);
      navigate(`/login${urlRedirect ? `?redirect=${urlRedirect}` : ""}`);
    },
    [navigate]
  );

  const requestPasswordReset = async (mail: string) => {
    try {
      const { success } = await checkMailAndSendCode({ email: mail });
      return success;
    } catch (error) {
      return { success: false };
    }
  };
  return {
    user: authState.user,
    loading: authState.loading,
    error: authState.error,
    signIn: signInUser,
    register: registerUser,
    logout: logoutUser,
    requestPasswordReset,
    isAuthenticated,
  };
};
