import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { ProgramType } from "@/types";
import ProgramSummary from "./ProgramSummary";

const ProgramList = () => {
  const { data, loading, error } = useFetchDataV2<ProgramType[]>(
    `${import.meta.env.VITE_API_ENDPOINT}/programs`
  );

  loading && <LoadingSpinner />;

  error && new Error("Une erreur est survenue: " + error);

  return (
    <>
      <h1 className="mb-5 text-2xl font-medium">Liste de mes programmes</h1>
      <div className="-mx-4 flex flex-wrap">
        {data?.map((program: ProgramType, key: number) => (
          <ProgramSummary
            className="mb-4 w-1/2  flex-1 px-4 md:w-1/3 md:max-w-[33%] lg:w-1/3 lg:max-w-[33%] xl:w-1/4 xl:max-w-[25%] "
            key={key}
            program={program}
          />
        ))}
      </div>
    </>
  );
};

export default ProgramList;
