import { useCallback, useState } from "react";

interface PostMultipartOptions<T> {
  url: string;
  data: T;
}

interface PostMultipartResponse {
  success: boolean;
  message?: string;
  data?: any;
}

interface UsePostMultipartResult<T> {
  postMultipart: (options: PostMultipartOptions<T>) => Promise<any>;
  loading: boolean;
  error?: string;
  response?: PostMultipartResponse;
}

function usePostMultipart<T>(): UsePostMultipartResult<T> {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [response, setResponse] = useState<PostMultipartResponse | undefined>(
    undefined
  );

  const postMultipart = useCallback(
    async ({ url, data }: PostMultipartOptions<T>): Promise<any> => {
      setLoading(true);
      setError(undefined);
      setResponse(undefined);

      const token = localStorage.getItem("jwtToken");

      try {
        const headers: HeadersInit = {};
        if (token) {
          headers["Authorization"] = `Bearer ${token}`;
        }

        const res = await fetch(url, {
          method: "POST",
          headers,
          body: data,
        });

        const result = await res.json();

        if (!res.ok) {
          throw new Error(result.message || "Something went wrong");
        }

        setResponse({
          success: true,
          message: result.message,
          data: result,
        });

        return result
      } catch (err: any) {
        setError(err.message || "An unknown error occurred");
        setResponse({
          success: false,
          message: err.message,
        });

        return {
          success: false,
          message: err.message,
        }
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { postMultipart, loading, error, response };
}

export default usePostMultipart;
