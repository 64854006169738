import EmailVerificationForm from "@/auth/components/EmailVerificationForm";
import { useAuth } from "@/auth/hooks/useAuth";
import { useNavigate } from "react-router-dom";

const EmailVerification = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();

  //Si l'utilisation est déjà connecté, on le redirige vers la page program
  if (isAuthenticated && user?.isVerified) {
    navigate(`/submit/programs`);
  }
  return (
    <div className="container">
      <EmailVerificationForm />
    </div>
  );
};

export default EmailVerification;
