import { Button, buttonVariants } from "@/components/ui/button";
import { User2 } from "lucide-react";
import { Link } from "react-router-dom";

const HeaderTop = () => {
  return (
    <div className="flex flex-wrap justify-between py-4">
      <Link
        to="/dashboard/add-project"
        className={`h-auto py-2 font-normal ${buttonVariants({
          variant: "roundedGray",
        })}`}
        style={{ borderRadius: "50px" }}
      >
        Ajouter un programme
      </Link>
      <div className="flex items-center gap-5">
        <Button variant={"roundedOrange"} className="h-auto py-2 font-normal">
          Carte des projets
        </Button>
        <div
          className="relative w-12 cursor-pointer"
          onClick={() => alert("je suis cliqué")}
        >
          <User2 size={35} />
          <span className="absolute right-4 top-1 flex size-4 items-center justify-center rounded-full border-2 border-background bg-destructive text-[10px] text-background">
            3
          </span>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
