import AuthenticationRequired from "@/components/AuthenticationRequired";
import NotFoundPage from "@/components/errors/404";
import ErrorBoundary from "@/components/errors/ErrorBoundary";
import LayoutDashboard from "@/dashboard/components/layout/Layout";
import Auth from "@/pages/auth/Auth";
import EmailVerification from "@/pages/auth/EmailVerification";
import ForgotPassword from "@/pages/auth/ForgotPassword";
import ResetPassword from "@/pages/auth/ResetPassword";
import AddProject from "@/pages/dashboard/AddProject";
import Dashboard from "@/pages/dashboard/Dashboard";
import DashboardProgram from "@/pages/dashboard/Program";
import DashboardPrograms from "@/pages/dashboard/Programs";
import DashboardProject from "@/pages/dashboard/Project";
import Home from "@/pages/submit/Home";
import Program from "@/pages/submit/Program";
import Programs from "@/pages/submit/Programs";
import Layout from "@/programs/components/layout/Layout";
import { Route, Routes } from "react-router-dom";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <Layout>
              <Home />
            </Layout>
          </ErrorBoundary>
        }
      />
      {/* Route : /submit */}
      <Route path="/submit">
        <Route
          index
          element={
            <ErrorBoundary>
              <Layout>
                <Home />
              </Layout>
            </ErrorBoundary>
          }
        />
        <Route
          path="program/:id"
          element={
            <ErrorBoundary>
              <Layout>
                <Program />
              </Layout>
            </ErrorBoundary>
          }
        />
        <Route
          path="programs"
          element={
            <ErrorBoundary>
              <Layout>
                <Programs />
              </Layout>
            </ErrorBoundary>
          }
        />
      </Route>
      <Route
        path="/login"
        element={
          <ErrorBoundary>
            <Layout>
              <Auth />
            </Layout>
          </ErrorBoundary>
        }
      />
      <Route
        path="/register/verify"
        element={
          <ErrorBoundary>
            <Layout>
              <EmailVerification />
            </Layout>
          </ErrorBoundary>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <ErrorBoundary>
            <Layout>
              <ForgotPassword />
            </Layout>
          </ErrorBoundary>
        }
      />
      <Route
        path="/reset-password"
        element={
          <ErrorBoundary>
            <Layout>
              <ResetPassword />
            </Layout>
          </ErrorBoundary>
        }
      />

      {/* Route : /dashboard */}
      <Route path="/dashboard">
        <Route
          index
          element={
            <ErrorBoundary>
              <AuthenticationRequired redirect="dashboard">
                <LayoutDashboard>
                  <Dashboard />
                </LayoutDashboard>
              </AuthenticationRequired>
            </ErrorBoundary>
          }
        />
        <Route
          path="programs"
          element={
            <ErrorBoundary>
              <AuthenticationRequired redirect="dashboard">
                <LayoutDashboard>
                  <DashboardPrograms />
                </LayoutDashboard>
              </AuthenticationRequired>
            </ErrorBoundary>
          }
        />
        <Route
          path="program/:id"
          element={
            <ErrorBoundary>
              <AuthenticationRequired redirect="dashboard">
                <LayoutDashboard>
                  <DashboardProgram />
                </LayoutDashboard>
              </AuthenticationRequired>
            </ErrorBoundary>
          }
        />
        <Route
          path="project/:id"
          element={
            <ErrorBoundary>
              <AuthenticationRequired redirect="dashboard">
                <LayoutDashboard>
                  <DashboardProject />
                </LayoutDashboard>
              </AuthenticationRequired>
            </ErrorBoundary>
          }
        />
        <Route
          path="add-project"
          element={
            <ErrorBoundary>
              <AuthenticationRequired redirect="dashboard">
                <LayoutDashboard>
                  <AddProject />
                </LayoutDashboard>
              </AuthenticationRequired>
            </ErrorBoundary>
          }
        />
      </Route>

      <Route
        path="*"
        element={
          <ErrorBoundary>
            <Layout>
              <NotFoundPage isPageChildren={false} />
            </Layout>
          </ErrorBoundary>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
