import ProgramList from "@/dashboard/components/program/ProgramList";

const DashboardPrograms = () => {
  return (
    <div>
      <ProgramList />
    </div>
  );
};

export default DashboardPrograms;
