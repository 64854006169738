import { useAuth } from "@/auth/hooks/useAuth";
import { File, Folder, Tree } from "@/components/extension/tree-view-api";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { cn } from "@/libs/utils";
import { ProgramType } from "@/types";
import {
  Award,
  Bell,
  ChevronLeft,
  ChevronRight,
  FolderIcon,
  LayoutDashboard,
  MessagesSquare,
  Power,
  ScrollText,
  Settings,
  User2,
} from "lucide-react";
import { FC, ReactNode } from "react";
import { Link, useParams } from "react-router-dom";

type LeftbarProps = {
  setIsOpen: (val: boolean) => void;
  isOpen: boolean;
};
const Leftbar: FC<LeftbarProps> = ({ setIsOpen, isOpen }) => {
  const { isAuthenticated, logout } = useAuth();
  const { data, loading, error } = useFetchDataV2<ProgramType[]>(
    `${import.meta.env.VITE_API_ENDPOINT}/programs`
  );
  const programs = data;
  const { id } = useParams<{ id: string }>();
  const elements = [
    {
      id: "1",
      isSelectable: true,
      name: "Mes programmes",
      children: programs
        ?.map((item: any, key: number) => {
          return {
            id: (key + 2).toString(),
            isSelectable: true,
            name: item.name,
            children: item?.projects
              ?.map((project: any, key: number) => {
                return {
                  id: (key * 2).toString(),
                  isSelectable: true,
                  name: item.name,
                };
              })
              .filter(Boolean),
          };
        })
        .filter(Boolean),
    },
  ];

  loading && <LoadingSpinner />;

  error && new Error("Une erreur est survenue: " + error);
  return (
    <div
      className={`sticky top-0 flex h-full min-h-screen flex-col py-8 ${
        isOpen ? "pl-8 pr-4" : "flex flex-col items-center px-2"
      }  bg-[#f4f4f4] bg-cover bg-center`}
    >
      <Link to="/dashboard">
        {isOpen ? (
          <img src="/logo.svg" alt="logo verdeo" width={90} className="mb-7" />
        ) : (
          <img
            src="/logo-min.svg"
            alt="logo verdeo"
            width={20}
            className="mb-7"
          />
        )}
      </Link>

      <ul>
        <LinkNav isOpen={isOpen} picto={<LayoutDashboard size={18} />} link="/">
          Dashboard
        </LinkNav>
      </ul>

      {isOpen ? (
        <Tree initialSelectedId={"1"} elements={elements} className="mb-4 ">
          <Folder element="Mes programmes" value="1" visibleChevron={true}>
            <div className="flex flex-col gap-1">
              {programs?.map((item: ProgramType, key: number) => (
                <Folder
                  element={item.name}
                  value={(key + 10).toString()}
                  key={key}
                  visibleChevron={true}
                  projectId={item.id}
                >
                  {item.projects.map((project: any, key: number) => (
                    <File
                      fileIcon=""
                      value={(key * 2).toString()}
                      key={key}
                      className="ml-4 w-full"
                    >
                      <Link
                        to={`/dashboard/project/${project.id}`}
                        className={`group flex w-full gap-2 rounded-sm p-1 text-[#878787] hover:text-[#1C5114] hover:opacity-100 ${
                          project.id == id ? "bg-gray-200/60" : ""
                        }`}
                      >
                        <FolderIcon className="flex size-5 text-[#878787] group-hover:text-[#1C5114]" />
                        <div className="flex-1">
                          <span
                            title={project.name}
                            className="line-clamp-1 text-left text-[13px] text-[#878787] hover:text-[#1C5114] hover:opacity-100"
                          >
                            {project.name}
                          </span>
                        </div>
                      </Link>
                    </File>
                  ))}
                </Folder>
              ))}
            </div>
          </Folder>
        </Tree>
      ) : (
        <button className="mb-4" onClick={() => setIsOpen(true)}>
          <FolderIcon className="flex size-5 opacity-70" />{" "}
        </button>
      )}
      <ul>
        <LinkNav isOpen={isOpen} picto={<ScrollText size={18} />} link="/">
          Mes factures
        </LinkNav>
        <LinkNav isOpen={isOpen} picto={<Award size={18} />} link="/">
          Mes certificats
        </LinkNav>
        <LinkNav isOpen={isOpen} picto={<User2 size={18} />} link="/">
          Profil
        </LinkNav>
        <LinkNav isOpen={isOpen} picto={<Bell size={18} />} link="/">
          Notification
        </LinkNav>
        <LinkNav isOpen={isOpen} picto={<MessagesSquare size={18} />} link="/">
          Messages
        </LinkNav>
      </ul>
      <ul className="mt-auto">
        <LinkNav
          isOpen={isOpen}
          picto={<Settings size={20} />}
          link="/"
          className="mb-0"
        >
          Paramètres
        </LinkNav>
        <div
          className={`mt-auto flex flex-wrap ${
            isOpen ? "justify-between" : "justify-center"
          } items-center transition-all`}
        >
          {!isAuthenticated ? (
            <Link
              to="/login"
              className="rounded-[4rem] border-2 border-foreground bg-background px-4 py-2 text-[#878787] transition-all hover:bg-foreground hover:text-background"
            >
              Connexion
            </Link>
          ) : (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <div className="flex cursor-pointer items-center gap-2 text-[#878787] transition-all hover:text-destructive">
                  <Power size={18} className="text-[#878787]" />
                  {isOpen && "Deconnexion"}
                </div>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    Êtes-vous sûr de vouloir vous déconnecter ?
                  </AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Annuler</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => logout(window.location.href)}
                  >
                    Se déconnecter
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}

          <button
            className="flex size-10 items-center justify-center text-foreground"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <ChevronLeft size={18} className="text-[#878787]" />
            ) : (
              <ChevronRight size={18} className="text-[#878787]" />
            )}
          </button>
        </div>
      </ul>
    </div>
  );
};

const LinkNav = ({
  isOpen,
  link,
  picto,
  children,
  className,
}: {
  isOpen: boolean;
  link: string;
  picto: ReactNode;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <li className={cn("mb-4", className)}>
      <Link
        to={`/dashboard${link}`}
        className={`line-clamp-1 flex flex-wrap items-center gap-2 text-left text-[13px] leading-4 text-[#878787]  hover:text-[#1C5114] ${
          !isOpen ? "mb-3  justify-center" : ""
        }`}
        title={children?.toString()}
      >
        {picto} {isOpen && children}
      </Link>
    </li>
  );
};
export default Leftbar;
