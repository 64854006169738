import { toast } from "@/components/ui/use-toast";
import { useCallback, useState } from "react";

interface PostDataOptions<T> {
  url: string;
  data: T;
}

interface PostDataResponse {
  success: boolean;
  message?: string;
  data?: any;
}

interface UsePostDataResult<T> {
  postData: (options: PostDataOptions<T>) => Promise<any>;
  loading: boolean;
  error?: string;
  response?: PostDataResponse;
}

function usePostData<T>(): UsePostDataResult<T> {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [response, setResponse] = useState<PostDataResponse | undefined>(
    undefined
  );

  const postData = useCallback(
    async ({ url, data }: PostDataOptions<T>): Promise<any> => {
      setLoading(true);
      setError(undefined);
      setResponse(undefined);

      const token = localStorage.getItem("jwtToken");

      try {
        const headers: HeadersInit = {
          "Content-Type": "application/json",
        };
        if (token) {
          headers["Authorization"] = `Bearer ${token}`;
        }

        const res = await fetch(url, {
          method: "POST",
          headers,
          body: JSON.stringify(data),
        });

        const result = await res.json();

        if (res.status === 400) {
          const message = result.error.violations[0].title;
          toast({
            className: "text-red-500",
            title: message,
          });
        }

        if (!res.ok) {
          throw new Error(result.message || "Something went wrong");
        }

        setResponse({
          success: true,
          message: result.message,
          data: result,
        });

        return result;
      } catch (err: any) {
        setError(err.message || "An unknown error occurred");
        setResponse({
          success: false,
          message: err.message,
        });

        return null;
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { postData, loading, error, response };
}

export default usePostData;
