import useCheckIsDevice from "@/hooks/useDeviceType";
import { convertStringToNumber } from "@/libs/utils";
import { useTypeProjectContext } from "@/programs/context/typeProgramContext";
import useProgram from "@/programs/hooks/useProgram";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import slugify from "slugify";
import EditPhotoHeader from "./EditPhotoHeader";

type HeaderFormProps = {
  project: string;
  description: string;
};
const HeaderForm: FC<HeaderFormProps> = ({ project, description }) => {
  const isMobile = useCheckIsDevice("mobile");
  const { id } = useParams<{ id: string }>();
  const idParams = convertStringToNumber(id);

  const { programData } = useProgram();
  const program = programData[idParams as number];
  const [searchParams] = useSearchParams();

  const projectId = searchParams.get("project") || "";
  const projectSt = program?.projects.find((p) => p.id == projectId) || false;

  const { typesProject } = useTypeProjectContext();
  const typeProject = typesProject?.find((t) => {
    const slug = slugify(t.name, {
      lower: true,
      remove: /[*+~.()'"!:@]/g,
    });

    return slug === projectSt.type;
  });

  const { t } = useTranslation("submit", {
    keyPrefix: "project",
  });

  const [picture, setPicture] = useState({
    uri: "",
    alt: "",
  });
  const [uploadLogoLoading, setUploadLogoLoading] = useState(false);

  useEffect(() => {
    setPicture({
      uri: projectSt?.defaultImage?.uri || "",
      alt: projectSt?.defaultImage?.alt || "",
    });
    setUploadLogoLoading(false);
  }, [projectSt]);

  if (!projectSt) return;

  return (
    <div className="relative mt-4 flex items-center">
      <div className="h-[150px] w-full overflow-hidden sm:w-1/4 sm:pr-4">
        <div className="group relative size-full overflow-hidden rounded-[20px] bg-[#f4f4f4]">
          <div className="absolute inset-2 z-10">
            <EditPhotoHeader
              setPicture={setPicture}
              picture={picture}
              uploadLogoLoading={uploadLogoLoading}
              setUploadLogoLoading={setUploadLogoLoading}
            />
          </div>
          {!uploadLogoLoading && picture.uri !== "" && (
            <img
              src={import.meta.env.VITE_MEDIA_BASE + picture.uri}
              alt={picture.alt}
              className="size-full object-cover   transition-all hover:blur-0 group-hover:blur-0"
            />
          )}
          {!uploadLogoLoading &&
            picture.uri === "" &&
            typeProject !== undefined &&
            typeProject.image && (
              <img
                src={import.meta.env.VITE_MEDIA_BASE + typeProject.image.uri}
                alt=""
                className="size-full object-cover   transition-all hover:blur-0 group-hover:blur-0"
              />
            )}
          {!uploadLogoLoading &&
            picture.uri === "" &&
            typeProject !== undefined &&
            !typeProject.image && (
              <img
                src="/pexels-pixabay-235925.jpg"
                alt=""
                className="size-full object-cover   transition-all hover:blur-0 group-hover:blur-0"
              />
            )}
        </div>
      </div>
      <div className="absolute left-0 top-0 z-10 flex h-full flex-col justify-end px-[17px] pb-[20px] md:static md:w-3/4">
        <h1 className="mb-0 line-clamp-1  text-[24px] font-bold leading-[3.5rem] text-white sm:text-[#626262]">
          {t("Projet")} : {project}
        </h1>
        {description && description !== "" && (
          <p
            className={`text-[14px] text-white sm:text-[#595959] ${
              isMobile ? "line-clamp-3" : ""
            }`}
            dangerouslySetInnerHTML={{
              __html: description?.replace(/\n/g, "<br />"),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderForm;
