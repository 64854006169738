import { useAuth } from "@/auth/hooks/useAuth";
import { langs } from "@/constants/constants";
import { ChevronDown, LogOut, Mail, User2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";

const Navigation = () => {
  const { isAuthenticated, logout } = useAuth();

  const { i18n } = useTranslation();
  const { t } = useTranslation("general");

  return (
    <div className="container mb-8">
      <nav className="relative flex h-16 items-center border-b md:h-24">
        <ul className="flex w-full items-center">
          <li>
            <a href="https://verdeo.fr">
              <img
                src="/logo.svg"
                alt="logo verdeo"
                width={138}
                className="w-24 md:w-[138px]"
              />
            </a>
          </li>
          <li className="ml-auto mr-4">
            <DropdownMenu>
              <DropdownMenuTrigger asChild className="cursor-pointer">
                <div className="flex items-center">
                  {i18n === undefined ||
                  i18n === null ||
                  i18n.language === undefined ||
                  i18n.language === null
                    ? "FR"
                    : i18n.language.split("-")[0].toUpperCase()}
                  {/* <span className={"fi fi-"+(i18n.language === "en" ? "gb" : (i18n.language === "fr-FR" ? "fr" : i18n.language))} style={{
                    width: '45px',
                    height: '25px',
                    backgroundSize: 'contain',
                  }}></span> */}
                  <ChevronDown className="size-4 opacity-50" />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="min-w-[10px]">
                <DropdownMenuGroup className="w-[50px]">
                  {langs.map((lang) => (
                    <DropdownMenuItem
                      className="cursor-pointer hover:bg-white focus:bg-white focus:text-black"
                      onClick={() => {
                        i18n.language !== lang && i18n.changeLanguage(lang);
                      }}
                    >
                      {lang.toUpperCase()}
                      {/* <span className={"fi fi-"+(lang === "en" ? "gb" : lang)+(i18n.language === lang ? " opacity-70 cursor-not-allowed" : "")} style={{
                                width: '45px',
                                height: '25px',
                                backgroundSize: 'contain',
                              }}></span> */}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </li>
          <li className="mr-4 text-right">
            <a
              href="https://verdeo.fr/contact/"
              className="group relative flex size-[42px] items-center justify-center rounded-full border border-foreground md:size-max md:border-0"
            >
              <span className="hidden text-xs md:block md:text-base">
                {t("Contactez-nous")}!{" "}
              </span>
              <span className="absolute left-0 top-full hidden h-px w-0 bg-foreground transition-all group-hover:w-full md:block"></span>
              <Mail className="block stroke-1 md:hidden" />
            </a>
          </li>
          {!isAuthenticated ? (
            <li>
              <Link
                to="/login"
                className="flex items-center justify-center rounded-[4rem] border-2 border-foreground bg-black p-2 transition-all hover:bg-foreground hover:text-background md:bg-background md:px-4"
              >
                <span className="hidden md:block">{t("Connexion")}</span>
                <User2 className="block stroke-1 text-background md:hidden" />
              </Link>
            </li>
          ) : (
            <li>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <div className="cursor-pointer  rounded-[4rem] border border-foreground bg-foreground p-2 transition-all hover:bg-foreground hover:text-background md:border-2 md:bg-background md:px-4">
                    <span className="hidden md:block">{t("Déconnexion")}</span>
                    <LogOut className="block stroke-1 text-background md:hidden" />
                  </div>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      {t("Êtes-vous sûr de vouloir vous déconnecter ?")}
                    </AlertDialogTitle>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Annuler</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => logout(window.location.href)}
                    >
                      {t("Se déconnecter")}
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
