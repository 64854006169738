import { ProjectType, ProjectUpdateType } from "@/types";

const API_URL = import.meta.env.VITE_API_ENDPOINT;

export const updateProject = async (data): Promise<ProjectType> => {
  const token = localStorage.getItem("jwtToken");

  let url = ''
  const dataProgram = {
  }
  if(data.id) {
    url = `${import.meta.env.VITE_IP_API}/api/project/edit/${data.id}`
  } else {
    url = `${import.meta.env.VITE_IP_API}/api/project/save`
    dataProgram.program = data.program
  }
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      data,
      ...dataProgram
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to sign in");
  }

  const project = await response.json();

  return project;
};

export const fetchProgram = async (id: number): Promise<any> => {
  const token = localStorage.getItem("jwtToken");

  const response = await fetch(`${import.meta.env.VITE_IP_API}/api/program/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  return await response.json();
}

export const fetchProject = async (id: number): Promise<ProjectType> => {
  const token = localStorage.getItem("jwtToken");

  const response = await fetch(`${import.meta.env.VITE_IP_API}/api/project/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  return await response.json();
}