import { RegisterData, SignInData, User } from "@/types";

const API_URL = import.meta.env.VITE_API_ENDPOINT;

export const signIn = async (data: SignInData): Promise<User> => {
  const response = await fetch(`${import.meta.env.VITE_IP_API}/auth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error("Failed to sign in");
  }

  const user = await response.json();
  // Stocker le token JWT dans le localStorage ou sessionStorage
  localStorage.setItem("jwtToken", user.token);
  localStorage.setItem("refreshToken", user.refresh_token);

  return user;
};

export const register = async (data: RegisterData): Promise<any> => {
  const response = await fetch(`${API_URL}/registration`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (response.status === 422) {
    return { user: null, errors: await response.json() };
  }
  if (!response.ok) {
    throw new Error("Failed to register");
  }

  const user = await response.json();

  return { user, errors: null };
};

export const checkAuth = async (): Promise<User | null> => {
  // Récupérer le token JWT du localStorage
  const token = localStorage.getItem("jwtToken");

  if (!token) {
    return null;
  }

  const response = await fetch(`${API_URL}/auth/me`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`, // Envoyer le token dans les headers
    },
  });
  if (!response.ok) {
    // Si la réponse n'est pas OK, cela peut signifier que le token est expiré ou invalide
    localStorage.removeItem("jwtToken"); // On supprime le token invalide
    return null;
  }

  return response.json();
};

export const checkCodeConfirmation = async (data: {
  code: string;
  email?: string;
}): Promise<any> => {
  const user = await checkAuth();
  if (!user) {
    return { success: false };
  }
  data = { ...data, email: user?.email };
  const response = await fetch(`${API_URL}/registration/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    return { success: false };
  }

  return { success: true };
};

export const resendCodeConfirmation = async (): Promise<any> => {
  const user = await checkAuth();
  if (!user) {
    return { success: false };
  }

  const response = await fetch(`${API_URL}/registration/resend`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: user?.email }),
  });

  if (!response.ok) {
    return { success: false };
  }

  return { success: true };
};

/*
 * Recherche si mail existe et envoi un code de restoration de mdp
 */
export const checkMailAndSendCode = async (data: {
  email?: string;
}): Promise<any> => {
  const response = await fetch(`${API_URL}/forgot`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: data.email }),
  });

  if (!response.ok) {
    return { success: false };
  }

  return { success: true };
};

export const checkCodeForgotPassword = async (data: {
  email?: string;
  code: string;
}): Promise<any> => {
  data = { ...data };
  const response = await fetch(`${API_URL}/forgot/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    return { success: false };
  }

  return { success: true };
};

export const resetPassword = async (data: {
  email: string;
  code: string;
  password: string;
  confirmPassword?: string;
}): Promise<any> => {
  data = { ...data, confirmPassword: data.password };
  const response = await fetch(`${API_URL}/forgot/save`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    return { success: false };
  }

  return { success: true };
};

export const resendCodeResetMdpConfirmation = async (
  mail: string
): Promise<any> => {
  const response = await fetch(`${API_URL}/forgot/resend`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: mail }),
  });

  if (!response.ok) {
    return { success: false };
  }

  return { success: true };
};
