import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { ProgramType } from "@/types";
import { useNavigate, useParams } from "react-router-dom";
import ProjectSummary from "./ProjectSummary";

const ProjectList = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data, loading, error } = useFetchDataV2<ProgramType>(
    `${import.meta.env.VITE_API_ENDPOINT}/program/${id}`
  );

  loading && <LoadingSpinner />;

  error && new Error("Une erreur est survenue: " + error);
  return (
    <div>
      <h1 className="mb-5 text-2xl font-medium">Mes projets</h1>
      <div className="-mx-4 mt-8 flex w-full flex-wrap items-start">
        {data?.projects?.map((project: any, key: number) => (
          <div
            className="mb-4 min-w-52 flex-1 px-4 sm:w-1/2 sm:max-w-[50%] md:w-1/3 md:max-w-[33%] lg:w-1/3 lg:max-w-[33%] xl:w-1/4 xl:max-w-[25%] "
            key={key}
          >
            <ProjectSummary
              key={key}
              id={project.id}
              type={project.type}
              picture={
                project?.defaultImage
                  ? import.meta.env.VITE_MEDIA_BASE + project?.defaultImage?.uri
                  : null
              }
              title={project.name}
              link={`?project=${project.id}`}
              status={project.status}
              onSelect={() => navigate(`/dashboard/project/${project.id}`)}
              typeProjectImage=""
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectList;
