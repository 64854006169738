import { useAuth } from "@/auth/hooks/useAuth";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

const AuthenticationRequired: FC<{ children: ReactNode; redirect: string }> = ({
  children,
  redirect,
}) => {
  const auth = useAuth();
  const navigate = useNavigate();

  if ((!auth.isAuthenticated || !auth.user?.isVerified) && !auth.loading) {
    navigate(`/login${redirect ? `?redirect=${redirect}` : ""}`);
    return <></>;
  }

  return children;
};

export default AuthenticationRequired;
