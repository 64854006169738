import { useEffect, useState } from "react";

interface UseFetchDataReturn<T> {
  data: T | null;
  loading: boolean;
  error: string | null;
}

export function useFetchData<T>(endpoint: string): UseFetchDataReturn<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchWithToken = async (token: string) => {
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) throw new Error("No refresh token available");

    const response = await fetch(
      `${import.meta.env.VITE_API_ENDPOINT}/token/refresh`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${refreshToken}`,
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
      }
    );

    if (!response.ok) throw new Error("Failed to refresh token");

    const data = await response.json();
    const newToken = data.token;
    const newRefreshToken = data.refresh_token;

    // Met à jour le token dans le localStorage
    localStorage.setItem("jwtToken", newToken);
    localStorage.setItem("refreshToken", newRefreshToken);

    return newToken;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        let token = localStorage.getItem("jwtToken");
        if (!token) throw new Error("No token available");

        let response = await fetchWithToken(token);

        if (response.status === 401) {
          // Le token est expiré, essayez de le renouveler
          token = await refreshToken();
          if (token) {
            response = await fetchWithToken(token);
          }
        }

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint]);

  return { data, loading, error };
}
