import LoginForm from "@/auth/components/LoginForm";
import RegistrationForm from "@/auth/components/RegistrationForm";
import { useAuth } from "@/auth/hooks/useAuth";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import useCheckIsDevice from "@/hooks/useDeviceType";
import { useNavigate, useSearchParams } from "react-router-dom";

const Auth = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user, loading } = useAuth();
  //Si l'utilisation est déjà connecté, on le redirige vers la page program
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const code = localStorage.getItem("code");

  if (code !== import.meta.env.VITE_API_CODE) {
    navigate(`/`);
  }
  const isMobile = useCheckIsDevice("mobile");
  if (isAuthenticated && user?.isVerified) {
    if (redirect) {
      window.location.href = redirect;
    } else {
      navigate(`/submit/programs`);
    }
    return <></>;
  }

  if (loading)
    return (
      <div className="container min-h-screen">
        <LoadingSpinner />
      </div>
    );
  //Todo: Rédirection de l'utilisateur vers la page code s'il n'est pas vérifié et on lui envoie un code mail
  return (
    <div className="container">
      {isMobile ? (
        <Tabs defaultValue="login" className="w-full">
          <TabsList className="mb-2 grid w-full grid-cols-2">
            <TabsTrigger value="login">Connexion</TabsTrigger>
            <TabsTrigger value="registration">Inscription</TabsTrigger>
          </TabsList>
          <TabsContent value="login" className="mb-4">
            <LoginForm />
          </TabsContent>
          <TabsContent value="registration" className="mb-4">
            <RegistrationForm />
          </TabsContent>
        </Tabs>
      ) : (
        <div className="mx-auto mb-5 mt-8 flex flex-wrap lg:w-3/4">
          <div className="mb-5 basis-full md:mb-0 md:basis-1/2 md:pr-6">
            <LoginForm />
          </div>
          <div className="basis-full md:basis-1/2 md:pl-6">
            <RegistrationForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default Auth;
