import { ChevronLeft } from "lucide-react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { buttonVariants } from "../ui/button";

const NotFoundPage: FC<{ isPageChildren?: boolean }> = ({
  isPageChildren = true,
}) => {
  return (
    <div
      className={`text-center min-h-60 flex justify-center flex-col items-center ${
        isPageChildren && "mt-14"
      }`}
    >
      <h1 className="text-2xl md:text-4xl mb-4 font-black text-destructive">
        404 - Page non trouvée
      </h1>
      <p>La page que vous recherchez n'existe pas.</p>
      <Link
        to="/submit"
        className={buttonVariants({ variant: "default", className: "mt-5" })}
      >
        <ChevronLeft /> Accueil
      </Link>
    </div>
  );
};

export default NotFoundPage;
