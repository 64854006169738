import ProjectList from "@/dashboard/components/project/ProjectList";

const DashboardProgram = () => {
  return (
    <div>
      <ProjectList />
    </div>
  );
};

export default DashboardProgram;
