import { convertStringToNumber } from "@/libs/utils";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProgramContext, { ProgramContextType } from "../context/programContext";

// Hook personnalisé pour utiliser le contexte
const useProgram = (): ProgramContextType => {
  const context = useContext(ProgramContext);
  const { id } = useParams<{ id: string }>();
  const idProgram = convertStringToNumber(id);
  const navigate = useNavigate();
  const location = useLocation();
  if (!context) {
    throw new Error("useProgram must be used within a ProgramProvider");
  }

  useEffect(() => {
    // Vérifier si l'utilisateur est déjà sur la page /programs
    const isOnProgramsPage = location.pathname === "/submit/programs";

    // Vérifier si l'idProgram est valide
    if (!idProgram && !isOnProgramsPage) {
      console.warn(`Program with ID ${idProgram} not found, redirecting...`);
      navigate("/submit/programs");
    }
    if (idProgram) {
      context.setCurrentId(idProgram);
    }
  }, [idProgram, context, navigate, location]);

  return context;
};
export default useProgram;
