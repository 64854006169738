const Dashboard = () => {
  return (
    <>
      <h1 className="mb-5 text-2xl font-medium">Tableau de bord</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis eveniet
        dolorum esse quidem dolorem cumque exercitationem amet itaque? Porro,
        omnis molestiae. Aperiam incidunt recusandae autem vitae unde commodi
        delectus earum!
      </p>
    </>
  );
};

export default Dashboard;
